import { makeAutoObservable, reaction } from "mobx";
import { useEffect, useState } from "react";
import { UserService } from "../../services/UserService";
import ApsModels from "../models";
import commonService from "../services/CommonService";
import { GridApi, GridReadyEvent } from "ag-grid-community";

export const ListCredLibraryTypes = [
  {
    value: ApsModels.CredLibraryTypeEnum.All,
    label: "All",
  },
  {
    value: ApsModels.CredLibraryTypeEnum.Aps,
    label: "APS Library",
  },
  {
    value: ApsModels.CredLibraryTypeEnum.Department,
    label: "Agency Library",
  },
];

class SystemStore {
  private _systemMessage: string = "Test System Message";
  private _currentDrillTemplateDepartment: number = 0;
  private _currentDrillTemplate: ApsModels.IDrillTemplateOutputDto =
    null as any;
  private _extraDrillInfo: ApsModels.IDrillOutputDto = null as any;
  private _drillReportFilters = {} as ApsModels.IDrillReportGridInputDto;
  private _systemError: "none" | "access-denied" | "in-token" = "none";
  private _nfirsListFilters: ApsModels.INfirsReportSearchInputDto | null = null;
  private _initialDepartmentIdReady = false;
  private _isoReports: ApsModels.IIsoReportListItemDto[] = [];
  private _showNotification = {
    show: false,
    message:
      "The APS website will be closed for scheduled maintenance on September 20th from 3:00 am to 5:00 am Pacific Time. We apologize for any inconvenience.",
  };
  private _has417Error: string | null = null;
  private _has408Error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get systemMessage() {
    return this._systemMessage;
  }

  get currentDrillTemplateDepartment() {
    return this._currentDrillTemplateDepartment;
  }

  get currentDrillTemplate() {
    return this._currentDrillTemplate;
  }

  get extraDrillInfo() {
    return this._extraDrillInfo;
  }

  get drillReportFilters() {
    const key = `drills-filters-${commonService.currentDepartment}-${commonService.userInfo.userId}`;
    try {
      return JSON.parse(localStorage.getItem(key) || "{}");
    } catch (error) {}
    return this._drillReportFilters;
  }

  get systemError() {
    return this._systemError;
  }

  get xnfirsListFilters() {
    return this._nfirsListFilters;
  }

  get initialDepartmentIdReady() {
    return this._initialDepartmentIdReady;
  }

  get isoReports() {
    return this._isoReports;
  }

  get showNotification() {
    return this._showNotification;
  }

  get has417Error() {
    return this._has417Error;
  }

  get has408Error() {
    return this._has408Error;
  }

  setSystemMessage(msg: string) {
    this._systemMessage = msg;
  }

  setCurrentDrillTemplateDepartment(id: number) {
    this._currentDrillTemplateDepartment = id;
  }

  setCurrentDrillTemplate(temp: ApsModels.IDrillTemplateOutputDto) {
    this._currentDrillTemplate = temp;
  }

  setExtraDrillInfo(val: ApsModels.IDrillOutputDto) {
    this._extraDrillInfo = val;
  }

  setDrillReportFilters(data: ApsModels.IDrillReportGridInputDto) {
    const key = `drills-filters-${commonService.currentDepartment}-${commonService.userInfo.userId}`;
    this._drillReportFilters = data;
    localStorage.setItem(key, JSON.stringify(data));
  }

  showSystemError(val: "none" | "access-denied" | "in-token") {
    //Will only set first "none" value
    if (this._systemError === "none") {
      this._systemError = val;
    } else if (val === "none") {
      this._systemError = val;
    }
  }

  setxNfirsListFilters(data: ApsModels.INfirsReportSearchInputDto | null) {
    this._nfirsListFilters = data;
  }

  setInitialDepartmentIdReady() {
    this._initialDepartmentIdReady = true;
  }

  setIsoReports(reports: ApsModels.IIsoReportListItemDto[]) {
    this._isoReports = [...reports];
  }

  setShowNotification(show: boolean, message: string) {
    this._showNotification = {
      show,
      message,
    };
  }

  setHas417Error(message: string | null) {
    return (this._has417Error = message);
  }

  setHas408Error(message: string | null) {
    return (this._has408Error = message);
  }
}

const systemStore = new SystemStore();
export default systemStore;

/**
 * Use to detect department selection.
 * @returns
 */
export const useDepartmentId = () => {
  const [initDepReady, setInitDepReady] = useState(
    systemStore.initialDepartmentIdReady
  );
  const [departmentId, setDepartmentId] = useState({
    current: initDepReady ? UserService.currentDepartment.get() : 0,
    previous: initDepReady ? UserService.currentDepartment.get() : 0,
    changed: false,
  });
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId((data) => {
        return {
          current: id,
          previous: data.current,
          changed: id !== data.current,
        };
      });
    });

    const disposer2 = reaction(
      () => systemStore.initialDepartmentIdReady,
      (n, p, i) => {
        if (!initDepReady && n) {
          setDepartmentId({
            current: UserService.currentDepartment.get(),
            previous: UserService.currentDepartment.get(),
            changed: false,
          });
        }
        setInitDepReady(n);
      }
    );

    return () => {
      disposer();
      disposer2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return departmentId;
};

export const useShowNotification = () => {
  const [showNotification, setShowNotification] = useState(
    systemStore.showNotification
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.showNotification,
      (n, p, i) => {
        setShowNotification(n);
      }
    );

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return showNotification;
};

export const useGridFilters = (
  key: string,
  gridApi: GridApi,
  init: (others: { [key: string]: any }) => void
) => {
  const [initial, setInitial] = useState(false);
  const props = {
    ...commonService.agGridHelpers.onStateChanges(key),
  };
  const [others, setOthers] = useState<{ [key: string]: any }>();
  const [ready, setReady] = useState(false);
  const otherKey = props._key + "_other";
  useEffect(() => {
    setOthers(JSON.parse(localStorage.getItem(otherKey) || "{}"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gridApi && !initial && others) {
      setInitial(true);
      init(others);
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, others]);

  const [gridEvent, setGridEvent] = useState<GridReadyEvent>();
  const reset = (params: GridReadyEvent) => {
    localStorage.removeItem(props._key);
    //localStorage.removeItem(otherKey);
    if (params) {
      params.columnApi.resetColumnState();
      params.api.setFilterModel({});
      params.api.refreshToolPanel();
    }
    commonService.agResizeColumns(params, true);
  };

  return {
    others,
    ready,
    reset() {
      gridEvent && reset(gridEvent);
    },
    resize() {
      gridEvent && commonService.agResizeColumns(gridEvent);
    },
    saveOthers(data: { [key: string]: any }) {
      localStorage.setItem(otherKey, JSON.stringify(data));
      setOthers(others);
    },
    reApplyFilters() {
      setTimeout(() => {
        //need to reapply filter after adding more data
        if (gridEvent) {
          props._reApplyFilters(gridEvent);
          //gridEvent.api.hideOverlay();
        }
      }, 100);
    },
    init: (params: GridReadyEvent) => {
      setGridEvent(params);
      commonService.agGridHelpers.resetFlag(props._key);
      //params.api.closeToolPanel();
      params.api.addEventListener("customResetColumnsAndFilters", () => {
        params && reset(params);
      });
      return props._init(params);
    },
    exportCSV: () => {
      const keys = (
        gridEvent?.columnApi
          ?.getAllDisplayedColumns()
          .map((column) => column.getColId()) || []
      ).filter((f) => {
        const col = gridEvent?.columnApi?.getColumn(f);
        if (col && col.getColDef()?.suppressColumnsToolPanel) {
          return false;
        }
        return true;
      });
      gridEvent?.api?.exportDataAsCsv({
        onlySelected: true,
        onlySelectedAllPages: true,
        columnKeys: keys,
        fileName: key,
      });
    },
    exportAllCSV: () => {
      const keys = (
        gridEvent?.columnApi
          ?.getAllColumns()
          .map((column) => column.getColId()) || []
      ).filter((f) => {
        const col = gridEvent?.columnApi?.getColumn(f);
        if (col && col.getColDef()?.suppressColumnsToolPanel) {
          return false;
        }
        return true;
      });
      const filters = gridEvent?.api?.getFilterModel();
      gridEvent?.api?.setFilterModel({});
      gridEvent?.api?.exportDataAsCsv({
        onlySelected: false,
        onlySelectedAllPages: false,
        columnKeys: keys,
        fileName: key,
      });
      gridEvent?.api?.setFilterModel(filters);
    },
    props: {
      ...props,
      sideBar: {
        ...commonService.agGridHelpers.sidebar,
      },
    },
  };
};
