import { useState } from "react";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import systemStore from "../../stores/SystemStore";

export enum NfirsFetchStatus {
  Default = 0,
  InProgress = 1,
  Complete = 2,
  Failed = 3,
}

export const useNfirsLookupEnum = (enumName: string, nameOnMessage: string) => {
  const [data, setData] = useState<ApsModels.ILookupIntDto[]>([]);
  const [status, setStatus] = useState<NfirsFetchStatus>(
    NfirsFetchStatus.Default
  );
  const [error, setError] = useState<any>();
  const [errorCode, setErrorCode] = useState<number>();
  const getData = async () => {
    setStatus(NfirsFetchStatus.InProgress);
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp(enumName)
      .then((rtn) => {
        setData((rtn || []).sort(commonService.sortByNumericProperty("value")));
        setError(undefined);
        setErrorCode(undefined);
        setStatus(NfirsFetchStatus.Complete);
      })
      .catch((err) => {
        if (commonService.getErrorStatusCode(err) === 404) {
          toastStore.showError(
            `Failed Getting ${nameOnMessage}`,
            `${nameOnMessage} Not Found`
          );
        } else {
          toastStore.showError(`Failed getting ${nameOnMessage}`, err);
        }
        setError(err);
        setErrorCode(commonService.getErrorStatusCode(err));
        setStatus(NfirsFetchStatus.Failed);
      });
  };

  return { data, setData, getData, status, error, errorCode };
};

export const useNfirsGetData = <T>(
  serviceAction: () => Promise<T>,
  nameOnMessage: string
) => {
  const [data, setData] = useState<T>();
  const [status, setStatus] = useState<NfirsFetchStatus>(
    NfirsFetchStatus.Default
  );
  const [error, setError] = useState<any>();
  const [errorCode, setErrorCode] = useState<number>();
  const getData = async () => {
    setStatus(NfirsFetchStatus.InProgress);
    await serviceAction()
      .then((rtn) => {
        setData(rtn);
        setError(undefined);
        setErrorCode(undefined);
        setStatus(NfirsFetchStatus.Complete);
      })
      .catch((err) => {
        if (commonService.getErrorStatusCode(err) === 404) {
          toastStore.showError(
            `Failed Getting ${nameOnMessage}`,
            `${nameOnMessage} Not Found`
          );
        } else {
          toastStore.showError(`Failed getting ${nameOnMessage}`, err);
        }
        setError(err);
        setErrorCode(commonService.getErrorStatusCode(err));
        setStatus(NfirsFetchStatus.Failed);
      });
  };

  return { data, setData, getData, status, error, errorCode };
};
